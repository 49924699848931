<template>
  <div class="result2 framing-y">
    <div class="framing-x bg-content">
      <article class="article">
        <h2 class="h2 text-center">職業適性分數座落位置：</h2>

        <div class="score-matrix text-center">
          <img alt="result" :src="matrixImage" width='100%'>
        </div>

        <h3 class="result-title text-primary text-center mb-lg-4 mt-lg-3">分析結果：</h3>

        <div class="infoBox text-primary text-center">
          <div class="infoBox-title">
            {{ description[0] }}
            <div class="text-small mt-2 mt-lg-3">的圖形</div>
          </div>
          <div class="text-small" v-html="description[1]"></div>
        </div>

        <h3 class="result-title text-primary text-center mt-4 mt-lg-5">職業建議方向：</h3>

        <div class="text-small text-center mt-lg-4">
          <span v-html="description[2]"></span>歡迎點選下方連結！
        </div>

        <div class="row no-gutters mt-5 pt-4 pt-lg-3 justify-content-center">
          <div class="col-12 col-lg-6 px-lg-2">
            <a :href="$root.$data.responsePayload.Sheet2.sales && $root.$data.responsePayload.Sheet2.sales.link" target="_blank" class="btn btn-primary w-100 footer-button">免費索取職業分析報告</a>
          </div>
          <div class="col-12 col-lg-3 mt-2 mt-lg-0 px-lg-2">
            <div class="input-group w-100">
              <div class="form-control btn-pretender btn-lighter">
                分享到
              </div>
              <div class="input-group-append">
                <a :href="lineShareLink" @click="shareCounter++" target="_blank" class="btn btn-lighter share-button"><img alt="line" src="../assets/icon-line.svg"></a>
                <a class="btn btn-lighter share-button" @click="shareToFacebook"><img alt="facebook" src="../assets/icon-facebook.svg"></a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import result2 from '../data/result2.js'

function isMobile() {
  let userAgentTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  let cssTest = window.matchMedia("(any-hover: none)").matches;
  return userAgentTest || cssTest;
}

export default {
  data() {
    return { shareCounter: 0 };
  },
  computed: {
    score1() {
      return parseInt(this.$root.$data.responsePayload.Sheet1.total || 0);
    },
    score2() {
      return { x: parseInt(this.$root.$data.responsePayload.Sheet2.sheet2_x || 25), y: parseInt(this.$root.$data.responsePayload.Sheet2.sheet2_y || 25) };
    },
    description() {
      return result2(this.score1, this.score2);
    },
    matrixImage() {
      return '/results/result' + this.score2.x + 'x' + this.score2.y + '@2x.png';
    },
    shareLink() {
      let current_sn = this.$root.$data.responsePayload.Sheet2.customer.sn;
      return window.location.origin + '/share/' + this.score2.x + 'x' + this.score2.y + '.html?sales_sn=' + this.$route.params.sales_sn + "&origin_sn=" + current_sn;
    },
    lineShareLink() {
      let shareDesc = this.description[1];
      let shareTitle = "我的職業適性分析結果：" + this.description[0];
      let msg = shareTitle + '%0D%0A' + shareDesc;

      let linkForDesktop = "https://social-plugins.line.me/lineit/share?text=" + msg + "&url=" + this.shareLink
      let linkForMobie = "http://line.naver.jp/R/msg/text/?" + msg + "%0D%0A" + this.shareLink;
      return isMobile ? linkForMobie : linkForDesktop;
    }
  },
  methods: {
    shareToFacebook() {
      event.preventDefault();
      event.stopImmediatePropagation();

      var shareDesc = this.description[1];
      var shareTitle = "我的職業適性分析結果：" + this.description[0];

      window.FB.ui({
        method: 'share',
        href: this.shareLink,
        quote: shareTitle + '\n' + shareDesc
      }, function(response){ console.log(response) });
    }
  }
}
</script>

<style scoped lang="scss">
.article {
  padding-bottom: 1.25rem;
}
.article {
  padding-bottom: 1rem;
}
.infoBox {
  padding: 1.75rem 1.25rem;
}
.infoBox-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.score-matrix {
  margin-top: 1.25rem;
  margin-bottom: 0.5625rem;
}
.score-matrix > img {
  width: 78.5714286%;
  max-width: calc(420px + 26px * 2);
}

.share-button {
  width: 2.5625rem;
  border: none;
  border-left: 1px solid #96C0D6;
}

@media screen and (min-width: 960px) {
.article {
  padding-bottom: 4.8125rem;
}
.infoBox {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.score-matrix {
  margin-top: 1.125rem;
}
.infoBox-title {
  font-size: 3.75rem;
  margin-bottom: 2.25rem;
}

.share-button {
  width: 3.8125rem;
  & > img {
    height: 1.875rem;
  }
}
}
</style>
