const mappings = [
  [
    [0, 59], 'right-top',
    "知足常樂",
    "行動力不強但為人較隨和，所以比較適合做支援性，服從性，例行性的工作，例如：總機、會計、門市、助理、公務人員、家庭主婦等等…我們是「知足常樂」的一群人。",
    "未來沒有穩定的工作，只有穩定的能力，"
  ],
  [
    [60, 999], 'right-top',
    "知足常樂",
    "行動力不強但為人較隨和，所以比較適合做支援性，服從性，例行性的工作，例如：總機、會計、門市、助理、公務人員、家庭主婦等等…我們是「知足常樂」的一群人。",
    "其實飛黃騰達是一種人生追求，粗茶淡飯也是一種生活態度，有人追求開跑車買名牌但是也必須有人開貨車送物流。<br>你想要過粗茶淡飯.知足常樂的人生嗎？如果不是。"
  ],
  [
    [0, 58], 'left-top',
    "千萬富翁",
    "行動力強，企圖心強，賺錢能力也較強，較為感性，所以服務業是你最好的選擇。<br>這是一個可能成為千萬富翁的圖形，千萬不要浪費了上天賦予你的禮物!",
    "挑戰高收入才是你最佳的選擇，"
  ],
  [
    [59, 999], 'left-top',
    "千萬富翁",
    "不要急著讓生活給予你所有的答案，有時候，你要拿出耐心等等。即使你向空谷喊話，也要等一會兒，才會聽見綿長的回音。堅持、努力行動你就能夠把書面的計劃、設計、藍圖變成現實中成功的高樓大廈。",
    "生命中總有那麼一段時光，充滿不安，"
  ],
  [
    [0, 45], 'left-bottom',
    "天生贏家",
    "行動力強，自我管理能力也強，所以成為贏家的機會就比一般人高很多，如果是在一般公司任職，成為公司中高階主管只是時間的問題，但也因為行動力強，自我管理能力也強所以也很適合自己創業。",
    "飛黃騰達的人生的劇本該如何寫，你早已決定！"
  ],
  [
    [46, 999], 'left-bottom',
    "天生贏家",
    "行動力強，自我管理能力也強，所以成為贏家的機會就比一般人高很多，如果是在一般公司任職，成為公司中高階主管只是時間的問題，但也因為行動力強，自我管理能力也強所以也很適合自己創業。",
    "優秀出色的表現是你的代名詞，如何更上一層樓！"
  ],
  [
    [0, 58], 'right-bottom',
    "精益求精",
    "雖然行動力不強，但是自我管理能力比較好，所以做些專業研究的工作其實比較適合，比如說：醫師，會計師，工程師，研究人員，銀行員等等…",
    "獨立卓越是你最好的發展方向，如何會有不一樣的人生？"
  ],
  [
    [59, 999], 'right-bottom',
    "精益求精",
    "雖然行動力不強，但是自我管理能力比較好，所以做些專業研究的工作其實比較適合，比如說：醫師，會計師，工程師，研究人員，銀行員等等…",
    "少做就是多做，不要貪多，如何把它做精做透很重要。"
  ],
  [
    [0, 61], 'right-center',
    "知足常樂",
    "行動力不強但為人較隨和，所以比較適合做支援性，服從性，例行性的工作，例如：總機、會計、門市、助理、公務人員、家庭主婦等等…我們是「知足常樂」的一群人。",
    "其實飛黃騰達是一種人生追求，粗茶淡飯也是一種生活態度，有人追求開跑車買名牌但是也必須有人開貨車送物流。<br>你想要過粗茶淡飯.知足常樂的人生嗎？如果不是。"
  ],
  [
    [62, 999], 'right-center',
    "精益求精",
    "雖然行動力不強，但是自我管理能力比較好，所以做些專業研究的工作其實比較適合，比如說：醫師，會計師，工程師，研究人員，銀行員等等…",
    "困擾總是存在，選擇永遠比努力重要。"
  ],
  [
    [0, 55], 'left-center',
    "千萬富翁",
    "行動力強，企圖心強，賺錢能力也較強，較為感性，所以服務業是你最好的選擇。<br>這是一個可能成為千萬富翁的圖形，千萬不要浪費了上天賦予你的禮物!",
    "相信自己勇敢行動，挑戰高收入才是你最佳的選擇。"
  ],
  [
    [56, 999], 'left-center',
    "天生贏家",
    "行動力強，自我管理能力也強，所以成為贏家的機會就比一般人高很多，如果是在一般公司任職，成為公司中高階主管只是時間的問題，但也因為行動力強，自我管理能力也強所以也很適合自己創業。",
    "優秀的表現是你最佳的寫照，該突破現況更上一層樓了。"
  ],
  [
    [0, 57], 'center-top',
    "千萬富翁",
    "行動力強，企圖心強，賺錢能力也較強，較為感性，所以服務業是你最好的選擇。<br>這是一個可能成為千萬富翁的圖形，千萬不要浪費了上天賦予你的禮物!",
    "有捨才有得，如何取捨才能讓自己活得精采？"
  ],
  [
    [58, 999], 'center-top',
    "知足常樂",
    "行動力不強但為人較隨和，所以比較適合做支援性，服從性，例行性的工作，例如：總機、會計、門市、助理、公務人員、家庭主婦等等…我們是「知足常樂」的一群人。",
    "城市的每個夜晚都有人在哭泣，<br>未曾哭過長夜的人不足以談人生，<br>情感的牽絆和工作的代價往往是個翹翹板，"
  ],
  [
    [0, 59], 'center-bottom',
    "天生贏家",
    "行動力強，自我管理能力也強，所以成為贏家的機會就比一般人高很多，如果是在一般公司任職，成為公司中高階主管只是時間的問題，但也因為行動力強，自我管理能力也強所以也很適合自己創業。",
    "愈是不順的路愈要沉住氣，艱難的路不是誰都有資格走的，是時候突破現況奮力向前了！"
  ],
  [
    [60, 999], 'center-bottom',
    "精益求精",
    "雖然行動力不強，但是自我管理能力比較好，所以做些專業研究的工作其實比較適合，比如說：醫師，會計師，工程師，研究人員，銀行員等等…",
    "你要努力才能換來想要的結果，別在最好的年齡辜負了最好的自己！"
  ],
  [
    [0, 999], 'center-center',
    "十字路口",
    "人生十字路口上，也許你正在做一些重大決定，徬徨無助不知該何去何從是你現在的寫照。",
    "不管前方的路有多苦，只要走的方向正確，不論多麼崎嶇不平，都比站在原地更接近幸福，讓我們協助你一起找出自己正確的方向！"
  ]
];

const externalMap = [
  {
    match: ({ x, y }) => { return (x === 24 && y === 24) || (x === 26 && y === 24) || (x === 26 && y === 26) || (x === 24 && y === 26) },
    description: '目前看起來自信心逐漸在消失當中.對於未來充滿困惑與不安.要知道城市的每個夜晚都有人在哭泣.未曾哭過長夜的人不足以談人生.昨天你流的汗會換來財富.今天你吃的苦會成為禮物.建議您聯絡發送適性測驗連結給您的朋友聊聊.相信對您會有幫助的!'
  },
  {
    match: ({ x, y }) => { return (x === 26 && y === 23) || (x === 23 && y === 23) || (x === 24 && y === 27) || (x === 27 && y === 27) },
    description: '感覺您目前應該是遇到一些困境需要尋找解決方案.這社會沒有絕對的道理.只有各自不同的立場.愈是不順的路愈要沉住氣.艱難的路不是誰都有資格走的.建議您聯絡發送適性測驗連結給您的朋友聊聊.相信對您會有幫助的!'
  },
]

function matrixArea(score) {
  let center = 25, area;

  if (score.x < center) area = 'left';
  else if (score.x > center) area = 'right';
  else area = 'center';

  area += '-';

  if (score.y < center) area += 'top';
  else if (score.y > center) area += 'bottom';
  else area += 'center';

  return area;
}

export default function (score1, score2) {
  let description;
  // score2 = { x: 24, y: 24 }
  for (let i = 0; i < mappings.length; i++) {
    let score1Min = mappings[i][0][0],
      score1Max = mappings[i][0][1],
      area = mappings[i][1];
    description = [mappings[i][2], mappings[i][3], mappings[i][4]];

    if (matrixArea(score2) == area && score1 >= score1Min && score1 <= score1Max) {
      // return description;
      break;
    }
  }
  for (let i = 0; i < externalMap.length; i++) {
    if (externalMap[i].match(score2)) {
      description[1] = externalMap[i].description;
      break;
    }
  }
  return description;

}
